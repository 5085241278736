import React, { Component } from 'react';

export class Submitted extends Component {

    render() {
        return (
            <div>
               <p>Das Formular wurde erfolgreich übermittelt!</p>
            </div>
        );
    }
}
